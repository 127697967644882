import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ImageCarousel from 'components/ImageCarousel';
import * as DocumentIdConstants from 'data/documentIdConstant';
import withBlockUI from 'hocs/withBlockUI';
import withMenu from 'hocs/withMenu';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import withSettings from 'hocs/withSettings';
import withUser from 'hocs/withUser';
import withMenuItemFunction from 'ui/MenuItem/hocs/withMenuItem';
import withOrderFunction from 'ui/Order/hocs/withOrder';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as StringUtils from 'utils/stringUtils';
import * as Toast from 'utils/toast';
import Menu from './menu/Menu';
import AboutDialog from './AboutDialog';
import BannerDialog from './BannerDialog';
import MenuCategoryListDialog from './MenuCategoryListDialog';
import AppBar from './AppBar';
import FloatingActionButton from './FloatingActionButton';
import Footer from './Footer';
import MenuItemNotFound from './MenuItemNotFound';
import RestaurantInfo from './RestaurantInfo';
import ScrollspyNav from './ScrollspyNav';
import Sidenav from './Sidenav';

const activeNavClass = 'is-active';

const paymentStatus = {
    Success: 'success',
    Failure: 'failure',
    InProgress: 'inprogress'
};

const Page = ({ location, menu, cart, settings, viewOrder, addItem }) => {
    const { categories } = menu;
    const { items } = cart;
    const { autoShowBannersOnStart, restaurantBanners } = settings;

    const isRedirect = location?.state?.paymentStatus ?? false;
    const shouldShowBannerDialog = autoShowBannersOnStart && restaurantBanners.length > 0 && !isRedirect;

    const [searchValue, setSearchValue] = useState('');
    const [activeBannerIndex, setBannerIndex] = useState(0);
    const [showMenuCategoryListDialog, setShowMenuCategoryListDialog] = useState(false);
    const [showSidenav, setShowSidenav] = useState(false);
    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const [showBannerDialog, setShowBannerDialog] = useState(shouldShowBannerDialog);

    const appBarRef = useRef();
    const imageCarouselRef = useRef();
    const restaurantInfoRef = useRef();
    const scrollSpyNavRef = useRef();
    const floatingActionButtonRef = useRef();

    const activeMenuCategoryGuid = useRef(null);

    const menuCategories = useMemo(() => {
        const getMatchedMenuItems = (menuCategory) => menuCategory.items
            .filter(x => !searchValue || StringUtils.contains(x.name, searchValue) || (x.displayCode && StringUtils.contains(x.displayCode, searchValue)));

        const getSelectedMenuItemsCount = (menuCategory, cartItems) => cartItems
            .filter(x => menuCategory.items.some(y => y.menuItemGuid === x.menuItemGuid))
            .reduce((total, item) => total + item.quantity, 0);

        return categories
            .map(x => ({
                ...x,
                items: getMatchedMenuItems(x),
                totalSelectedQty: getSelectedMenuItemsCount(x, items)
            }))
            .filter(x => x.items.length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, items, searchValue]);

    const banners = restaurantBanners.length
        ? restaurantBanners
            .sort((a, b) => a.seq - b.seq)
            .map(x => x.image)
        : ['/img/menu-default-banner.png'];

    useEffect(() => {
        if (!isRedirect) return;

        viewOrder();

        const status = location.state.paymentStatus;

        if (status === paymentStatus.Success) {
            Toast.showSuccessMessage('Payment completed successfully.');
        } else if (status === paymentStatus.Failure) {
            Toast.showErrorMessage('Payment failed.');
        } else if (status === paymentStatus.InProgress) {
            Toast.showWarningMessage('Payment pending for your approval.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        toggleNavLinksClickListener(true);
        activateCurrentInViewNavLink();

        return () => {
            window.removeEventListener('scroll', onScroll);
            toggleNavLinksClickListener(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    // Getters functions
    const getNavLinkElement = menuCategoryGuid => document.querySelector(`a[href="#${menuCategoryGuid}"]`);
    const getMenuCategoryContentElement = menuCategoryGuid => document.getElementById(menuCategoryGuid);

    // Dialog functions
    const openSidenav = () => setShowSidenav(true);
    const closeSidenav = () => setShowSidenav(false);
    const openAboutDialog = () => setShowAboutDialog(true);
    const closeAboutDialog = () => setShowAboutDialog(false);
    const openMenuCategoryListDialog = () => setShowMenuCategoryListDialog(true);
    const closeMenuCategoryListDialog = () => setShowMenuCategoryListDialog(false);
    const openBannerDialog = () => setShowBannerDialog(true);
    const closeBannerDialog = () => setShowBannerDialog(false);

    // Event listener core functions
    const onScroll = () => {
        const appBar = appBarRef.current;
        const imageCarouselEl = imageCarouselRef.current;
        const restaurantInfoEl = restaurantInfoRef.current;
        const scrollSpyNavEl = scrollSpyNavRef.current;
        const floatingActionButtonEl = floatingActionButtonRef.current;

        const toggleAppBarAndScrollSpyNavStyles = () => {
            // Note : The calculation `window.innerHeight * 0.02` compensates for the 2vh top margin of RestaurantInfo, which is positioned above the ImageCarousel
            const threshold = imageCarouselEl.scrollHeight + restaurantInfoEl.scrollHeight - appBar.getScrollHeight() - window.innerHeight * 0.02;

            if (window.scrollY <= threshold) {
                appBar.setAppBarTransparency(true);
                scrollSpyNavEl?.classList.remove('sticky');
            } else {
                appBar.setAppBarTransparency(false);
                scrollSpyNavEl?.classList.add('sticky');
            }
        };

        const toggleFloatingActionButtonStyles = () => {
            if (window.scrollY > 0) {
                floatingActionButtonEl.style.visibility = 'visible';
            } else {
                floatingActionButtonEl.style.visibility = 'hidden';
            }
        }

        activateCurrentInViewNavLink();
        toggleAppBarAndScrollSpyNavStyles();
        toggleFloatingActionButtonStyles();
    };

    const toggleNavLinksClickListener = (addEventListener) => {
        const scrollSpyNavEl = scrollSpyNavRef.current;
        if (!scrollSpyNavEl) return;

        scrollSpyNavEl.querySelectorAll('a').forEach(x => {
            if (addEventListener) {
                x.addEventListener('click', onNavLinkClick);
            } else {
                x.removeEventListener('click', onNavLinkClick);
            }
        });
    };

    // Event listener helper functions
    const activateCurrentInViewNavLink = () => {
        const activate = (menuCategoryGuid) => {
            const navLinkEl = getNavLinkElement(menuCategoryGuid);
            navLinkEl.parentElement.classList.add(activeNavClass);
        };

        const deactivateAll = (menuCategoryGuids) => {
            for (const menuCategoryGuid of menuCategoryGuids) {
                const navLinkEl = getNavLinkElement(menuCategoryGuid);
                navLinkEl.parentElement.classList.remove(activeNavClass);
            }
        };

        const scrollIntoViewHorizontally = (menuCategoryGuid) => {
            const navLinkEl = getNavLinkElement(menuCategoryGuid);

            const navTabEl = document.getElementById(DocumentIdConstants.PageMenuNavTab);
            const navTabRect = navTabEl.getBoundingClientRect();
            const navLinkRect = navLinkEl.getBoundingClientRect();
            const navLinkCenter = navLinkRect.left + navLinkRect.width / 2;

            const scrollLeft = navTabEl.scrollLeft + navLinkCenter - navTabRect.left - navTabRect.width / 2;
            navTabEl.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        };

        const menuCategoryGuids = menuCategories.map(x => x.menuCategoryGuid);

        const appBar = appBarRef.current;
        const scrollSpyNavEl = scrollSpyNavRef.current;

        const appBarHeight = appBar.getScrollHeight();
        const scrollSpyNavHeight = scrollSpyNavEl?.scrollHeight ?? 0; // Note : ScrollspyNav is skipped for rendering if menuCategories is empty

        for (const menuCategoryGuid of menuCategoryGuids) {
            const menuCategoryContentEl = getMenuCategoryContentElement(menuCategoryGuid);

            // Note : Effective offset top does not consider sticky / fixed elements 
            const effectiveOffsetTop = menuCategoryContentEl.offsetTop - appBarHeight - scrollSpyNavHeight;
            const isScrolledPastMenuCategory = window.scrollY > effectiveOffsetTop + menuCategoryContentEl.scrollHeight;

            if (!isScrolledPastMenuCategory) {
                deactivateAll(menuCategoryGuids);
                activate(menuCategoryGuid);
                scrollIntoViewHorizontally(menuCategoryGuid);
                activeMenuCategoryGuid.current = menuCategoryGuid;

                break;
            }
        }
    };

    const onNavLinkClick = (e) => {
        EventUtils.preventDefault(e);

        const navHref = e.target.getAttribute('href');
        const menuCategoryGuid = navHref.includes('#') ? navHref.replace('#', '') : '';
        doNavScroll(menuCategoryGuid);
    };

    const doNavScroll = (menuCategoryGuid) => {
        const menuCategoryContentEl = getMenuCategoryContentElement(menuCategoryGuid);
        if (!menuCategoryContentEl) return;

        const scrollSpyNavEl = scrollSpyNavRef.current;
        const scrollSpyNavHeight = scrollSpyNavEl.scrollHeight;
        const appBarHeight = appBarRef.current.getScrollHeight();

        const effectiveOffsetTop = menuCategoryContentEl.offsetTop - appBarHeight - scrollSpyNavHeight;

        window.scrollTo({ top: effectiveOffsetTop });
    };

    // Callback functions
    const viewMenuItem = (menuItemGuid) => {
        addItem(menuItemGuid)
            .then(e => {
                if (e.actionMessage) {
                    Toast.showSuccessMessage(e.actionMessage);
                }
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            });
    };

    const onSearchValueChange = useCallback((value) => {
        // useCallback is used here to prevent SearchBar useEffect from being called when page rerenders
        setSearchValue(value);
    }, []);

    const onImageCarouselClick = () => {
        if (!restaurantBanners.length) return;
        openBannerDialog();
    };

    return (
        <>
            <AppBar
                menuCategories={menuCategories}
                onSearchValueChange={onSearchValueChange}
                openSidenav={openSidenav}
                ref={appBarRef}
            />

            <ImageCarousel
                className="w-100"
                style={{ height: '30vh', maxWidth: 'var(--max-width)' }}
                imageStyle={{ objectFit: 'cover' }}
                data={banners}
                activeIndex={activeBannerIndex}
                interval={showBannerDialog ? null : 3000}
                onClick={onImageCarouselClick}
                handleChange={activeIndex => setBannerIndex(activeIndex)}
                ref={imageCarouselRef}
            />

            <RestaurantInfo ref={restaurantInfoRef} />

            {menuCategories.length > 0
                ? <>
                    <ScrollspyNav
                        menuCategories={menuCategories}
                        openMenuCategoryListDialog={openMenuCategoryListDialog}
                        ref={scrollSpyNavRef}
                    />

                    <Menu menuCategories={menuCategories} viewMenuItem={viewMenuItem} />

                    <div className="bg-white" style={{ height: '30vh' }}></div>
                </>
                : <MenuItemNotFound>
                    {`${searchValue
                        ? `We couldn't find Item "${searchValue}" in our menu.`
                        : `We don't have any available items to sell for now.`}`
                    }
                </MenuItemNotFound>
            }

            <FloatingActionButton ref={floatingActionButtonRef} />

            {menu.categories.length > 0 && <Footer />}

            {showMenuCategoryListDialog &&
                <MenuCategoryListDialog
                    menuCategories={menuCategories}
                    activeMenuCategoryGuid={activeMenuCategoryGuid.current}
                    doNavScroll={doNavScroll}
                    closeDialog={closeMenuCategoryListDialog}
                />
            }

            {showSidenav &&
                <Sidenav
                    openAboutDialog={openAboutDialog}
                    closeSidenav={closeSidenav}
                />
            }

            {showAboutDialog &&
                <AboutDialog closeDialog={closeAboutDialog} />
            }

            {showBannerDialog &&
                <BannerDialog
                    banners={banners}
                    activeBannerIndex={activeBannerIndex}
                    closeDialog={closeBannerDialog}
                />
            }
        </>
    );
};

const hocs = [
    withBlockUI,
    withMenu,
    withUser,
    withOrderService,
    withSettings,
    withRouter,
    withMenuItemFunction,
    withOrderFunction
];

export default ComponentUtils.compose(hocs)(Page);