import { useState } from 'react';
import Dialog from 'components/Dialog';
import ImageCarousel from 'components/ImageCarousel';

const BannerDialog = ({ banners, activeBannerIndex: initialActiveBannerIndex, closeDialog }) => {
    const [activeBannerIndex, setActiveBannerIndex] = useState(initialActiveBannerIndex);

    return (
        <Dialog
            isOpen
            centered
            closeDialog={closeDialog}
        >
            <ImageCarousel
                style={{ height: '60vh' }}
                data={banners}
                activeIndex={activeBannerIndex}
                handleChange={activeIndex => setActiveBannerIndex(activeIndex)}
            />
        </Dialog>
    );
}

export default BannerDialog;