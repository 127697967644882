import BigNumber from 'bignumber.js';
import * as RoundingMethodConstants from 'data/roundingMethodConstant';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import * as NumberUtils from 'utils/numberUtils';

// Helper class to perform calculation for Cart
export default class CartCalculator {
    constructor(settings, onStateUpdate) {
        this.settings = settings;
        this.onStateUpdate = onStateUpdate;
    }

    // Cart
    calculateFinalTotal(netTotal) {
        // Calculate FinalTotal via rounding
        const { roundingOption } = this.settings;

        if (roundingOption === RoundingMethodConstants.NoRounding) return netTotal;
        else if (roundingOption === RoundingMethodConstants.RoundToNearest5Cent) return Math.round(netTotal * 20) / 20;
        else return Math.floor(netTotal * 10) / 10;
    }

    calculateSurcharge(items) {
        // Calculate surcharge (service charge, tax, etc.) for Cart
        const sum = (items, selector) => items.reduce((total, item) => total + selector(item), 0);

        const totalQuantity = sum(items, item => item.quantity);
        const serviceCharge = sum(items, item => item.serviceCharge);
        const tax = sum(items, item => item.tax);
        const totalAmountIncTax = sum(items, item => item.subTotalIncTax);
        const totalAmountExTax = sum(items, item => item.subTotalExTax);
        const netTotal = totalAmountIncTax + serviceCharge;
        const finalTotal = this.calculateFinalTotal(netTotal);

        this.onStateUpdate({
            totalQuantity,
            serviceCharge: this._round(serviceCharge),
            tax: this._round(tax),
            totalAmountIncTax: this._round(totalAmountIncTax),
            totalAmountExTax: this._round(totalAmountExTax),
            netTotal: this._round(netTotal),
            finalTotal: this._round(finalTotal),
            roundingAdjustment: this._round(finalTotal - netTotal)
        });
    }

    // CartItem
    calculateCartItemSubTotal(cartItem) {
        // Calculate SubTotal for CartItem
        const cartItemUnitPrice = this._initBigNumber(cartItem.price);
        const takeAwayItemUnitPrice = this._initBigNumber(cartItem.takeAwayItem?.price ?? 0);

        let cartItemTotalUnitPrice = cartItemUnitPrice.plus(takeAwayItemUnitPrice);

        if (!cartItem.menuSetMealGuid) {
            cartItemTotalUnitPrice = cartItem.modifierItems.reduce((total, modifierItem) => {
                const modifierItemSubTotal = this._initBigNumber(modifierItem.surcharge).times(modifierItem.quantity);
                modifierItem.subTotal = this._roundBNToNumber(modifierItemSubTotal);
                return total.plus(modifierItemSubTotal);
            }, cartItemTotalUnitPrice);
        } else {
            cartItemTotalUnitPrice = cartItem.setMealItems.reduce((total, cartSetMealItem) => {
                this.calculateCartSetMealItemSubTotal(cartSetMealItem);
                return total.plus(this._initBigNumber(cartSetMealItem.subTotal));
            }, cartItemTotalUnitPrice);
        }

        cartItem.subTotal = this._roundBNToNumber(cartItemTotalUnitPrice.times(cartItem.quantity));
    }

    calculateCartSetMealItemSubTotal(cartSetMealItem) {
        // Calculate SubTotal for CartSetMealItem
        const modifierItemSubTotals = cartSetMealItem.modifierItems.reduce((total, modifierItem) => {
            const modifierItemSubTotal = this._initBigNumber(modifierItem.surcharge).times(modifierItem.quantity);
            modifierItem.subTotal = this._roundBNToNumber(modifierItemSubTotal);

            return total.plus(modifierItemSubTotal);
        }, this._initBigNumber(0));

        const cartSetMealItemSubTotal = this._initBigNumber(cartSetMealItem.price).plus(modifierItemSubTotals).times(cartSetMealItem.quantity);
        cartSetMealItem.subTotal = this._roundBNToNumber(cartSetMealItemSubTotal);
    }

    calculateCartItemsSurcharge(items) {
        // Calculate surcharge (service charge, tax, etc.) for CartItems
        const sum = (items, selector) => items.reduce((total, result) => total + selector(result), 0);

        const surchargeCalculationState = {
            remainingServiceCharge: 0,
            remainingTax: 0
        };

        for (const item of items) {
            const results = [];

            const takeAwayItemUnitPrice = item.takeAwayItem?.price ?? 0;

            if (item.takeAwayItem !== null) {
                results.push(this._calculateItemSurcharge(
                    surchargeCalculationState,
                    item.serviceType,
                    item.takeAwayItem.taxCode,
                    item.takeAwayItem.taxRate,
                    this._initBigNumber(takeAwayItemUnitPrice).times(item.quantity)
                ));
            }

            if (!item.menuSetMealGuid) {
                results.push(this._calculateItemSurcharge(
                    surchargeCalculationState,
                    item.serviceType,
                    item.taxCode,
                    item.taxRate,
                    this._initBigNumber(item.subTotal).minus(this._initBigNumber(takeAwayItemUnitPrice).times(item.quantity))
                ));
            } else {
                for (const setMealItem of item.setMealItems) {
                    results.push(this._calculateItemSurcharge(
                        surchargeCalculationState,
                        item.serviceType,
                        setMealItem.taxCode,
                        setMealItem.taxRate,
                        this._initBigNumber(setMealItem.subTotal).times(item.quantity)
                    ));
                }
            }

            item.serviceCharge = this._round(sum(results, x => x.serviceCharge));
            item.tax = this._round(sum(results, x => x.tax));
            item.subTotalIncTax = this._round(sum(results, x => x.subTotalIncTax));
            item.subTotalExTax = this._round(sum(results, x => x.subTotalExTax));
        }
    }

    _calculateItemSurcharge(state, serviceType, taxCode, taxRate, subTotal) {
        // Calculate surcharge for item (Item can be either take away item, normal item or set meal item)
        const { enableServiceCharge, enableTax, serviceChargeRate } = this.settings;
        const isInclusive = this.settings.serviceChargeAndTaxInclusive;

        // Init values
        const shouldCalculateServiceCharge = enableServiceCharge && serviceType !== ServiceTypeConstants.TakeAway && serviceChargeRate > 0;
        const shouldCalculateTax = enableTax && taxCode !== null && taxRate !== null && taxRate > 0;
        const totalPercentage = 100 + (shouldCalculateServiceCharge ? serviceChargeRate : 0) + (shouldCalculateTax ? taxRate : 0);

        let serviceChargeRounded = 0;
        let taxRounded = 0;

        // Calculate service charge
        if (shouldCalculateServiceCharge) {
            const serviceCharge = isInclusive
                ? subTotal.div(totalPercentage).times(serviceChargeRate).plus(state.remainingServiceCharge)
                : subTotal.div(100).times(serviceChargeRate).plus(state.remainingServiceCharge);

            serviceChargeRounded = this._roundBNToNumber(serviceCharge);
            state.remainingServiceCharge = serviceCharge.minus(serviceChargeRounded).toNumber();
        }

        // Calculate tax
        if (shouldCalculateTax) {
            const tax = isInclusive
                ? subTotal.div(totalPercentage).times(taxRate).plus(state.remainingTax)
                : subTotal.div(100).times(taxRate).plus(state.remainingTax);

            taxRounded = this._roundBNToNumber(tax);
            state.remainingTax = tax.minus(taxRounded).toNumber();
        }

        // Calculate subtotal
        const subTotalIncTax = isInclusive
            ? this._roundBNToNumber(subTotal.minus(serviceChargeRounded))
            : this._roundBNToNumber(subTotal.plus(taxRounded));

        const subTotalExTax = isInclusive
            ? this._roundBNToNumber(subTotal.minus(serviceChargeRounded).minus(taxRounded))
            : this._roundBNToNumber(subTotal);

        return {
            serviceCharge: serviceChargeRounded,
            tax: taxRounded,
            subTotalIncTax,
            subTotalExTax
        };
    }

    _initBigNumber(value) {
        // Handle null as zero (aligned with javascript behaviour)
        // BigNumber.js default constructor can't handle null
        if (value === null) value = 0;
        return new BigNumber(value);
    }

    _roundBNToNumber(value) {
        // Convert BigNumber to number data type
        const { currencyDecimal } = this.settings;
        return value.dp(currencyDecimal).toNumber();
    }

    _round(value) {
        return NumberUtils.roundNumberFromFormat(value, this.settings.currencyFormat);
    }
}