import { useState, useEffect, useRef } from 'react';

// Note : This implementation technique is known as debouncing
const SearchBar = ({ onSearchValueChange }) => {
    const [value, setValue] = useState('');

    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        const timer = setTimeout(() => onSearchValueChange(value), 500);

        return () => clearTimeout(timer);
    }, [value, onSearchValueChange]);

    return (
        <div className="w-100 px-3">
            <input
                className="form-control fs-6 pe-4"
                autoFocus
                type="text"
                placeholder="Search Menu"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    );
}

export default SearchBar;