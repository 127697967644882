import EmptyLine from 'components/EmptyLine';
import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemPrice from 'components.special/MenuItemPrice';
import ItemUIRenderer from '../../utils/itemUIRenderer';

const OrderItemPanel = ({ orderItem, isLastOrderItem }) => {
    const itemUIRenderer = ItemUIRenderer.createFromOrder(orderItem);
    const isQuantityChanged = orderItem.quantity !== orderItem.originalQuantity;

    return (
        <>
            <div className="d-flex mt-3 px-3">
                <MenuItemImage
                    className="border"
                    style={{ borderRadius: '6px' }}
                    imageSrc={orderItem.image}
                    name={orderItem.name}
                    width='70px'
                    height='70px'
                    isLazyLoading
                />

                <div className="flex-grow-1 d-flex flex-column ms-3">
                    <div className="d-flex">
                        <div className="flex-grow-1 fs-5 me-2">
                            <span className="fw-bold">
                                <MenuItemName name={orderItem.name} displayCode={orderItem.displayCode} />
                            </span>

                            {isQuantityChanged &&
                                <span className="text-muted ms-2" style={{ textDecoration: 'line-through' }}>
                                    {`x${orderItem.originalQuantity}`}
                                </span>
                            }

                            <span className="fw-bold ms-2">
                                {`x${orderItem.quantity}`}
                            </span>
                        </div>

                        <div>
                            {isQuantityChanged &&
                                <MenuItemPrice
                                    as="div"
                                    className="fs-6 text-muted"
                                    style={{ textDecoration: 'line-through' }}
                                    value={orderItem.originalSubTotal}
                                    isCurrencyFormat
                                />
                            }

                            <MenuItemPrice
                                as="div"
                                className="fs-5"
                                value={orderItem.subTotal}
                                isCurrencyFormat
                            />
                        </div>
                    </div>

                    {itemUIRenderer.renderCustomizeContents()}
                </div>
            </div>

            <EmptyLine className={`mt-3 ${isLastOrderItem ? '' : 'mx-3'}`} />
        </>
    );
};

export default OrderItemPanel;